.main_block{
    
    background: rgb(72,102,112);
    background: linear-gradient(180deg, rgba(72,102,112,1) 0%, rgba(101,105,108,0.20632002801120453) 57%, rgba(32,37,41,1) 100%);
    
}

#loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
